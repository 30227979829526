import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var MatchEventsIframeConfig = function MatchEventsIframeConfig() {
  this.componentType = 0;
  this.appUrl = "";
  this.webUrl = "";
  this.wapUrl = "";
};
MatchEventsIframeConfig.prototype._clone = function () {
  return new NimoBuss.MatchEventsIframeConfig();
};
MatchEventsIframeConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchEventsIframeConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchEventsIframeConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.appUrl);
  os.writeString(2, this.webUrl);
  os.writeString(3, this.wapUrl);
};
MatchEventsIframeConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.appUrl = is.readString(1, false, this.appUrl);
  this.webUrl = is.readString(2, false, this.webUrl);
  this.wapUrl = is.readString(3, false, this.wapUrl);
};
NimoBuss.MatchEventsIframeConfig = MatchEventsIframeConfig;
export default MatchEventsIframeConfig;