import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './ProgressTab';
var MatchProgressConfig = function MatchProgressConfig() {
  this.showTie = 0;
  this.groupShow = 0;
  this.defaultTabNum = 0;
  this.componentType = 0;
  this.componentTitle = "";
  this.isDefault = 0;
  this.tabList = new Taf.Vector(new NimoBuss.ProgressTab());
};
MatchProgressConfig.prototype._clone = function () {
  return new NimoBuss.MatchProgressConfig();
};
MatchProgressConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchProgressConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchProgressConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.showTie);
  os.writeInt32(1, this.groupShow);
  os.writeInt32(2, this.defaultTabNum);
  os.writeInt32(3, this.componentType);
  os.writeString(4, this.componentTitle);
  os.writeInt32(5, this.isDefault);
  os.writeVector(6, this.tabList);
};
MatchProgressConfig.prototype.readFrom = function (is) {
  this.showTie = is.readInt32(0, false, this.showTie);
  this.groupShow = is.readInt32(1, false, this.groupShow);
  this.defaultTabNum = is.readInt32(2, false, this.defaultTabNum);
  this.componentType = is.readInt32(3, false, this.componentType);
  this.componentTitle = is.readString(4, false, this.componentTitle);
  this.isDefault = is.readInt32(5, false, this.isDefault);
  this.tabList = is.readVector(6, false, this.tabList);
};
NimoBuss.MatchProgressConfig = MatchProgressConfig;
export default MatchProgressConfig;