import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var PageInfo = function PageInfo() {
  this.pageSize = 0;
  this.offset = 0;
  this.totalCount = 0;
};
PageInfo.prototype._clone = function () {
  return new NimoBuss.PageInfo();
};
PageInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PageInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PageInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.pageSize);
  os.writeInt32(1, this.offset);
  os.writeInt32(2, this.totalCount);
};
PageInfo.prototype.readFrom = function (is) {
  this.pageSize = is.readInt32(0, false, this.pageSize);
  this.offset = is.readInt32(1, false, this.offset);
  this.totalCount = is.readInt32(2, false, this.totalCount);
};
NimoBuss.PageInfo = PageInfo;
export default PageInfo;