import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FanLevelResponse = function FanLevelResponse() {
  this.level = 0;
};
FanLevelResponse.prototype._clone = function () {
  return new NimoBuss.FanLevelResponse();
};
FanLevelResponse.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FanLevelResponse.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FanLevelResponse.prototype.writeTo = function (os) {
  os.writeInt32(0, this.level);
};
FanLevelResponse.prototype.readFrom = function (is) {
  this.level = is.readInt32(0, false, this.level);
};
NimoBuss.FanLevelResponse = FanLevelResponse;
export default FanLevelResponse;