import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var GetFanGroupCreateSwitchRsp = function GetFanGroupCreateSwitchRsp() {
  this.allowLevel = 0;
  this.allowCreateFanClub = 0;
  this.anchorLevel = 0;
};
GetFanGroupCreateSwitchRsp.prototype._clone = function () {
  return new NimoBuss.GetFanGroupCreateSwitchRsp();
};
GetFanGroupCreateSwitchRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanGroupCreateSwitchRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanGroupCreateSwitchRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.allowLevel);
  os.writeInt32(1, this.allowCreateFanClub);
  os.writeInt32(2, this.anchorLevel);
};
GetFanGroupCreateSwitchRsp.prototype.readFrom = function (is) {
  this.allowLevel = is.readInt32(0, false, this.allowLevel);
  this.allowCreateFanClub = is.readInt32(1, false, this.allowCreateFanClub);
  this.anchorLevel = is.readInt32(2, false, this.anchorLevel);
};
NimoBuss.GetFanGroupCreateSwitchRsp = GetFanGroupCreateSwitchRsp;
export default GetFanGroupCreateSwitchRsp;