import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './EventIntroConfig';
import './TeamIntroConfig';
import './MatchScheduleConfig';
import './MatchProgressConfig';
import './MatchVideoConfig';
import './MatchPKConfig';
import './MatchShareConfig';
import './MatchDataConfig';
import './MatchRealTimeDataConfig';
import './MatchEventsIframeConfig';
var WebMatchConfigModel = function WebMatchConfigModel() {
  this.enable = 0;
  this.style = 0;
  this.teamPk = 0;
  this.webBackImg = "";
  this.webHeadImg = "";
  this.enableList = new Taf.Vector(new Taf.STRING());
  this.eventIntroConfig = new NimoBuss.EventIntroConfig();
  this.teamIntroConfig = new NimoBuss.TeamIntroConfig();
  this.scheduleConfig = new NimoBuss.MatchScheduleConfig();
  this.eventProcessConfig = new NimoBuss.MatchProgressConfig();
  this.matchVideoConfig = new NimoBuss.MatchVideoConfig();
  this.teamPKConfig = new NimoBuss.MatchPKConfig();
  this.matchShareConfig = new NimoBuss.MatchShareConfig();
  this.matchDataConfig = new NimoBuss.MatchDataConfig();
  this.matchRealTimeDataConfig = new NimoBuss.MatchRealTimeDataConfig();
  this.matchEventsIframeConfig = new NimoBuss.MatchEventsIframeConfig();
};
WebMatchConfigModel.prototype._clone = function () {
  return new NimoBuss.WebMatchConfigModel();
};
WebMatchConfigModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WebMatchConfigModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WebMatchConfigModel.prototype.writeTo = function (os) {
  os.writeInt32(0, this.enable);
  os.writeInt64(1, this.style);
  os.writeInt64(2, this.teamPk);
  os.writeString(3, this.webBackImg);
  os.writeString(4, this.webHeadImg);
  os.writeVector(5, this.enableList);
  os.writeStruct(6, this.eventIntroConfig);
  os.writeStruct(7, this.teamIntroConfig);
  os.writeStruct(8, this.scheduleConfig);
  os.writeStruct(9, this.eventProcessConfig);
  os.writeStruct(10, this.matchVideoConfig);
  os.writeStruct(11, this.teamPKConfig);
  os.writeStruct(12, this.matchShareConfig);
  os.writeStruct(13, this.matchDataConfig);
  os.writeStruct(14, this.matchRealTimeDataConfig);
  os.writeStruct(15, this.matchEventsIframeConfig);
};
WebMatchConfigModel.prototype.readFrom = function (is) {
  this.enable = is.readInt32(0, false, this.enable);
  this.style = is.readInt64(1, false, this.style);
  this.teamPk = is.readInt64(2, false, this.teamPk);
  this.webBackImg = is.readString(3, false, this.webBackImg);
  this.webHeadImg = is.readString(4, false, this.webHeadImg);
  this.enableList = is.readVector(5, false, this.enableList);
  this.eventIntroConfig = is.readStruct(6, false, this.eventIntroConfig);
  this.teamIntroConfig = is.readStruct(7, false, this.teamIntroConfig);
  this.scheduleConfig = is.readStruct(8, false, this.scheduleConfig);
  this.eventProcessConfig = is.readStruct(9, false, this.eventProcessConfig);
  this.matchVideoConfig = is.readStruct(10, false, this.matchVideoConfig);
  this.teamPKConfig = is.readStruct(11, false, this.teamPKConfig);
  this.matchShareConfig = is.readStruct(12, false, this.matchShareConfig);
  this.matchDataConfig = is.readStruct(13, false, this.matchDataConfig);
  this.matchRealTimeDataConfig = is.readStruct(14, false, this.matchRealTimeDataConfig);
  this.matchEventsIframeConfig = is.readStruct(15, false, this.matchEventsIframeConfig);
};
NimoBuss.WebMatchConfigModel = WebMatchConfigModel;
export default WebMatchConfigModel;