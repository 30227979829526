import ua from '@client/common/utils/ua';
function isBadPushServices() {
  var browser = ua.parser.getBrowser();
  // Opera/Maxthon 不支持Web Push
  if (browser.name === 'Opera' || browser.name === 'Maxthon') return true;

  // 新版Edge 支持Web Push了
  // if (
  //   ua.parser.satisfies({
  //     edge: '>70'
  //   })
  // ) {
  //   return true;
  // }

  if (browser.name === 'Chrome' || browser.name === 'Chromium') {
    var uaInfo = navigator.userAgent.toLocaleLowerCase();

    // 国产浏览器不支持 Web Push
    var regs = [/bidubrowser/,
    // 百度
    /metasr/,
    // 搜狗
    /tencenttraveler/,
    // QQ
    /qqbrowser/ // QQ
    ];
    return regs.some(function (re) {
      return re.test(uaInfo);
    }) || !!navigator.mimeTypes['application/vnd.chromium.remoting-viewer'] // hack for 360
    ;
  }
  return false;
}
export default function supportWebPush() {
  return navigator.cookieEnabled && 'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window && 'fetch' in window && 'indexedDB' in window && Object.prototype.hasOwnProperty.call(ServiceWorkerRegistration.prototype, 'showNotification') && Object.prototype.hasOwnProperty.call(PushSubscription.prototype, 'getKey') && !isBadPushServices();
}