import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GetRealtimeReplayRsp = function GetRealtimeReplayRsp() {
  this.iCode = 0;
  this.sMessage = "";
  this.sURL = "";
  this.iStartTime = 0;
  this.iEndTime = 0;
  this.iVideoType = 0;
};
GetRealtimeReplayRsp.prototype._clone = function () {
  return new Nimo.GetRealtimeReplayRsp();
};
GetRealtimeReplayRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRealtimeReplayRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRealtimeReplayRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
  os.writeString(2, this.sURL);
  os.writeInt32(3, this.iStartTime);
  os.writeInt32(4, this.iEndTime);
  os.writeInt32(5, this.iVideoType);
};
GetRealtimeReplayRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.sURL = is.readString(2, false, this.sURL);
  this.iStartTime = is.readInt32(3, false, this.iStartTime);
  this.iEndTime = is.readInt32(4, false, this.iEndTime);
  this.iVideoType = is.readInt32(5, false, this.iVideoType);
};
Nimo.GetRealtimeReplayRsp = GetRealtimeReplayRsp;
export default GetRealtimeReplayRsp;