import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PayChatOption';
var SendPayChatRsp = function SendPayChatRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.lUid = 0;
  this.sContent = "";
  this.lAnchorId = 0;
  this.iLimitTime = 0;
  this.iGuardianRemainCount = 0;
  this.tPayChatOption4Guardian = new Nimo.PayChatOption();
};
SendPayChatRsp.prototype._clone = function () {
  return new Nimo.SendPayChatRsp();
};
SendPayChatRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendPayChatRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendPayChatRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeInt64(2, this.lUid);
  os.writeString(3, this.sContent);
  os.writeInt64(4, this.lAnchorId);
  os.writeInt32(5, this.iLimitTime);
  os.writeInt32(6, this.iGuardianRemainCount);
  os.writeStruct(7, this.tPayChatOption4Guardian);
};
SendPayChatRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.lUid = is.readInt64(2, false, this.lUid);
  this.sContent = is.readString(3, false, this.sContent);
  this.lAnchorId = is.readInt64(4, false, this.lAnchorId);
  this.iLimitTime = is.readInt32(5, false, this.iLimitTime);
  this.iGuardianRemainCount = is.readInt32(6, false, this.iGuardianRemainCount);
  this.tPayChatOption4Guardian = is.readStruct(7, false, this.tPayChatOption4Guardian);
};
Nimo.SendPayChatRsp = SendPayChatRsp;
export default SendPayChatRsp;