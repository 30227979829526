import _defineProperty from "@babel/runtime/helpers/defineProperty";
import EURI from '@client/jce/Nimo/EURI';
import NonPublicLiveBeginNotice from '@client/jce/Nimo/NonPublicLiveBeginNotice';
import LiveTypeChange from '@client/jce/Nimo/LiveTypeChange';
import TafMessageSocketModel from '../TafMessageSocketModel';
var EUriNonPublicLiveBeginNotice = EURI.EUriNonPublicLiveBeginNotice,
  EUriLiveTypeChange = EURI.EUriLiveTypeChange;
export { EUriNonPublicLiveBeginNotice, EUriLiveTypeChange };
export default new TafMessageSocketModel({
  broadcasts: _defineProperty(_defineProperty({}, EUriNonPublicLiveBeginNotice, [EUriNonPublicLiveBeginNotice, NonPublicLiveBeginNotice]), EUriLiveTypeChange, [EUriLiveTypeChange, LiveTypeChange])
});