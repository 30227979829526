import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var MatchScheduleConfig = function MatchScheduleConfig() {
  this.componentType = 0;
  this.componentTitle = "";
  this.isDefault = 0;
};
MatchScheduleConfig.prototype._clone = function () {
  return new NimoBuss.MatchScheduleConfig();
};
MatchScheduleConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchScheduleConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchScheduleConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.componentTitle);
  os.writeInt32(2, this.isDefault);
};
MatchScheduleConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.componentTitle = is.readString(1, false, this.componentTitle);
  this.isDefault = is.readInt32(2, false, this.isDefault);
};
NimoBuss.MatchScheduleConfig = MatchScheduleConfig;
export default MatchScheduleConfig;