import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var BadgeInfoView = function BadgeInfoView() {
  this.badgeId = 0;
  this.anchorId = 0;
  this.gainTime = 0;
  this.level = 0;
  this.countryCode = "";
  this.badgeName = "";
  this.badgeIcon = "";
  this.nowIntimate = 0;
  this.nextNeedIntimate = 0;
  this.wear = 0;
  this.anchorName = "";
  this.currentRoom = 0;
  this.badgeStatus = 0;
  this.growthValue = 0;
  this.limitGrowthValue = 0;
  this.avatar = "";
  this.roomId = 0;
  this.onLive = 0;
  this.templateType = 0;
  this.avatarBoxUrl = "";
  this.dynamicAvatarBoxUrl = "";
  this.leftBadgeIcon = "";
  this.leftBadgeIconV2 = "";
  this.freeGrowthValue = 0;
  this.freeGrowthValueLimit = 0;
};
BadgeInfoView.prototype._clone = function () {
  return new NimoBuss.BadgeInfoView();
};
BadgeInfoView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BadgeInfoView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BadgeInfoView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.badgeId);
  os.writeInt64(1, this.anchorId);
  os.writeInt64(2, this.gainTime);
  os.writeInt32(3, this.level);
  os.writeString(4, this.countryCode);
  os.writeString(5, this.badgeName);
  os.writeString(6, this.badgeIcon);
  os.writeInt64(7, this.nowIntimate);
  os.writeInt64(8, this.nextNeedIntimate);
  os.writeInt32(9, this.wear);
  os.writeString(10, this.anchorName);
  os.writeInt32(11, this.currentRoom);
  os.writeInt32(12, this.badgeStatus);
  os.writeInt32(13, this.growthValue);
  os.writeInt32(14, this.limitGrowthValue);
  os.writeString(15, this.avatar);
  os.writeInt64(16, this.roomId);
  os.writeInt32(17, this.onLive);
  os.writeInt32(18, this.templateType);
  os.writeString(19, this.avatarBoxUrl);
  os.writeString(20, this.dynamicAvatarBoxUrl);
  os.writeString(21, this.leftBadgeIcon);
  os.writeString(22, this.leftBadgeIconV2);
  os.writeInt64(23, this.freeGrowthValue);
  os.writeInt64(24, this.freeGrowthValueLimit);
};
BadgeInfoView.prototype.readFrom = function (is) {
  this.badgeId = is.readInt64(0, false, this.badgeId);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.gainTime = is.readInt64(2, false, this.gainTime);
  this.level = is.readInt32(3, false, this.level);
  this.countryCode = is.readString(4, false, this.countryCode);
  this.badgeName = is.readString(5, false, this.badgeName);
  this.badgeIcon = is.readString(6, false, this.badgeIcon);
  this.nowIntimate = is.readInt64(7, false, this.nowIntimate);
  this.nextNeedIntimate = is.readInt64(8, false, this.nextNeedIntimate);
  this.wear = is.readInt32(9, false, this.wear);
  this.anchorName = is.readString(10, false, this.anchorName);
  this.currentRoom = is.readInt32(11, false, this.currentRoom);
  this.badgeStatus = is.readInt32(12, false, this.badgeStatus);
  this.growthValue = is.readInt32(13, false, this.growthValue);
  this.limitGrowthValue = is.readInt32(14, false, this.limitGrowthValue);
  this.avatar = is.readString(15, false, this.avatar);
  this.roomId = is.readInt64(16, false, this.roomId);
  this.onLive = is.readInt32(17, false, this.onLive);
  this.templateType = is.readInt32(18, false, this.templateType);
  this.avatarBoxUrl = is.readString(19, false, this.avatarBoxUrl);
  this.dynamicAvatarBoxUrl = is.readString(20, false, this.dynamicAvatarBoxUrl);
  this.leftBadgeIcon = is.readString(21, false, this.leftBadgeIcon);
  this.leftBadgeIconV2 = is.readString(22, false, this.leftBadgeIconV2);
  this.freeGrowthValue = is.readInt64(23, false, this.freeGrowthValue);
  this.freeGrowthValueLimit = is.readInt64(24, false, this.freeGrowthValueLimit);
};
NimoBuss.BadgeInfoView = BadgeInfoView;
export default BadgeInfoView;