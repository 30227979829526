import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var ActivityPositionManager = function ActivityPositionManager() {
  this.id = 0;
  this.adminName = "";
  this.createTime = 0;
  this.updateTime = 0;
  this.version = 0;
  this.activityPosition = 0;
  this.countries = "";
  this.finishTime = 0;
  this.gameIds = "";
  this.languages = "";
  this.picture = "";
  this.pushDeviceType = 0;
  this.redirect = 0;
  this.redirectUrl = "";
  this.startTime = 0;
  this.status = 0;
  this.title = "";
  this.weight = 0;
  this.jump = 0;
  this.activityType = "";
  this.titleLang = "";
  this.specifyLiveRoom = 0;
  this.isWhiteList = 0;
  this.bookStartTime = "";
  this.bookEndTime = "";
  this.countDown = 0;
  this.roomIds = "";
  this.remindType = 0;
  this.whiteListId = 0;
  this.iconUrl = "";
  this.tips = "";
  this.tipsLang = "";
  this.beginTime = 0;
  this.endTime = 0;
  this.promote = 0;
  this.remindStrategy = 0;
  this.userCountryCode = "";
  this.activityStatus = 0;
  this.userTarget = 0;
  this.floatingId = 0;
  this.tagId = 0;
  this.tagNumber = 0;
  this.gameChannelId = 0;
  this.englishTitle = "";
};
ActivityPositionManager.prototype._clone = function () {
  return new NimoBuss.ActivityPositionManager();
};
ActivityPositionManager.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ActivityPositionManager.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ActivityPositionManager.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeString(1, this.adminName);
  os.writeInt64(2, this.createTime);
  os.writeInt64(3, this.updateTime);
  os.writeInt32(4, this.version);
  os.writeInt32(5, this.activityPosition);
  os.writeString(6, this.countries);
  os.writeInt64(7, this.finishTime);
  os.writeString(8, this.gameIds);
  os.writeString(9, this.languages);
  os.writeString(10, this.picture);
  os.writeInt32(11, this.pushDeviceType);
  os.writeInt32(12, this.redirect);
  os.writeString(13, this.redirectUrl);
  os.writeInt64(14, this.startTime);
  os.writeInt32(15, this.status);
  os.writeString(16, this.title);
  os.writeInt32(17, this.weight);
  os.writeInt32(18, this.jump);
  os.writeString(19, this.activityType);
  os.writeString(20, this.titleLang);
  os.writeInt32(21, this.specifyLiveRoom);
  os.writeInt32(22, this.isWhiteList);
  os.writeString(23, this.bookStartTime);
  os.writeString(24, this.bookEndTime);
  os.writeInt64(25, this.countDown);
  os.writeString(26, this.roomIds);
  os.writeInt32(27, this.remindType);
  os.writeInt32(28, this.whiteListId);
  os.writeString(29, this.iconUrl);
  os.writeString(30, this.tips);
  os.writeString(31, this.tipsLang);
  os.writeInt64(32, this.beginTime);
  os.writeInt64(33, this.endTime);
  os.writeInt32(34, this.promote);
  os.writeInt32(35, this.remindStrategy);
  os.writeString(36, this.userCountryCode);
  os.writeInt32(37, this.activityStatus);
  os.writeInt32(38, this.userTarget);
  os.writeInt32(39, this.floatingId);
  os.writeInt32(40, this.tagId);
  os.writeInt32(41, this.tagNumber);
  os.writeInt32(42, this.gameChannelId);
  os.writeString(43, this.englishTitle);
};
ActivityPositionManager.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.adminName = is.readString(1, false, this.adminName);
  this.createTime = is.readInt64(2, false, this.createTime);
  this.updateTime = is.readInt64(3, false, this.updateTime);
  this.version = is.readInt32(4, false, this.version);
  this.activityPosition = is.readInt32(5, false, this.activityPosition);
  this.countries = is.readString(6, false, this.countries);
  this.finishTime = is.readInt64(7, false, this.finishTime);
  this.gameIds = is.readString(8, false, this.gameIds);
  this.languages = is.readString(9, false, this.languages);
  this.picture = is.readString(10, false, this.picture);
  this.pushDeviceType = is.readInt32(11, false, this.pushDeviceType);
  this.redirect = is.readInt32(12, false, this.redirect);
  this.redirectUrl = is.readString(13, false, this.redirectUrl);
  this.startTime = is.readInt64(14, false, this.startTime);
  this.status = is.readInt32(15, false, this.status);
  this.title = is.readString(16, false, this.title);
  this.weight = is.readInt32(17, false, this.weight);
  this.jump = is.readInt32(18, false, this.jump);
  this.activityType = is.readString(19, false, this.activityType);
  this.titleLang = is.readString(20, false, this.titleLang);
  this.specifyLiveRoom = is.readInt32(21, false, this.specifyLiveRoom);
  this.isWhiteList = is.readInt32(22, false, this.isWhiteList);
  this.bookStartTime = is.readString(23, false, this.bookStartTime);
  this.bookEndTime = is.readString(24, false, this.bookEndTime);
  this.countDown = is.readInt64(25, false, this.countDown);
  this.roomIds = is.readString(26, false, this.roomIds);
  this.remindType = is.readInt32(27, false, this.remindType);
  this.whiteListId = is.readInt32(28, false, this.whiteListId);
  this.iconUrl = is.readString(29, false, this.iconUrl);
  this.tips = is.readString(30, false, this.tips);
  this.tipsLang = is.readString(31, false, this.tipsLang);
  this.beginTime = is.readInt64(32, false, this.beginTime);
  this.endTime = is.readInt64(33, false, this.endTime);
  this.promote = is.readInt32(34, false, this.promote);
  this.remindStrategy = is.readInt32(35, false, this.remindStrategy);
  this.userCountryCode = is.readString(36, false, this.userCountryCode);
  this.activityStatus = is.readInt32(37, false, this.activityStatus);
  this.userTarget = is.readInt32(38, false, this.userTarget);
  this.floatingId = is.readInt32(39, false, this.floatingId);
  this.tagId = is.readInt32(40, false, this.tagId);
  this.tagNumber = is.readInt32(41, false, this.tagNumber);
  this.gameChannelId = is.readInt32(42, false, this.gameChannelId);
  this.englishTitle = is.readString(43, false, this.englishTitle);
};
NimoBuss.ActivityPositionManager = ActivityPositionManager;
export default ActivityPositionManager;