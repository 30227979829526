import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './EventIntroConfig';
import './TeamIntroConfig';
import './MatchScheduleConfig';
import './MatchProgressConfig';
import './MatchVideoConfig';
import './MatchPKConfig';
import './MatchShareConfig';
import './MatchDataConfig';
import './MatchRealTimeDataConfig';
import './MatchEventsIframeConfig';
var TagConfigModel = function TagConfigModel() {
  this.num = 0;
  this.title = "";
  this.titleLang = "";
  this.isDefault = 0;
  this.enableList = new Taf.Vector(new Taf.STRING());
  this.eventIntroConfig = new NimoBuss.EventIntroConfig();
  this.teamIntroConfig = new NimoBuss.TeamIntroConfig();
  this.scheduleConfig = new NimoBuss.MatchScheduleConfig();
  this.eventProcessConfig = new NimoBuss.MatchProgressConfig();
  this.matchVideoConfig = new NimoBuss.MatchVideoConfig();
  this.teamPKConfig = new NimoBuss.MatchPKConfig();
  this.matchShareConfig = new NimoBuss.MatchShareConfig();
  this.matchDataConfig = new NimoBuss.MatchDataConfig();
  this.matchRealTimeDataConfig = new NimoBuss.MatchRealTimeDataConfig();
  this.matchEventsIframeConfig = new NimoBuss.MatchEventsIframeConfig();
};
TagConfigModel.prototype._clone = function () {
  return new NimoBuss.TagConfigModel();
};
TagConfigModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TagConfigModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TagConfigModel.prototype.writeTo = function (os) {
  os.writeInt64(0, this.num);
  os.writeString(1, this.title);
  os.writeString(2, this.titleLang);
  os.writeInt64(3, this.isDefault);
  os.writeVector(4, this.enableList);
  os.writeStruct(5, this.eventIntroConfig);
  os.writeStruct(6, this.teamIntroConfig);
  os.writeStruct(7, this.scheduleConfig);
  os.writeStruct(8, this.eventProcessConfig);
  os.writeStruct(9, this.matchVideoConfig);
  os.writeStruct(10, this.teamPKConfig);
  os.writeStruct(11, this.matchShareConfig);
  os.writeStruct(12, this.matchDataConfig);
  os.writeStruct(13, this.matchRealTimeDataConfig);
  os.writeStruct(14, this.matchEventsIframeConfig);
};
TagConfigModel.prototype.readFrom = function (is) {
  this.num = is.readInt64(0, false, this.num);
  this.title = is.readString(1, false, this.title);
  this.titleLang = is.readString(2, false, this.titleLang);
  this.isDefault = is.readInt64(3, false, this.isDefault);
  this.enableList = is.readVector(4, false, this.enableList);
  this.eventIntroConfig = is.readStruct(5, false, this.eventIntroConfig);
  this.teamIntroConfig = is.readStruct(6, false, this.teamIntroConfig);
  this.scheduleConfig = is.readStruct(7, false, this.scheduleConfig);
  this.eventProcessConfig = is.readStruct(8, false, this.eventProcessConfig);
  this.matchVideoConfig = is.readStruct(9, false, this.matchVideoConfig);
  this.teamPKConfig = is.readStruct(10, false, this.teamPKConfig);
  this.matchShareConfig = is.readStruct(11, false, this.matchShareConfig);
  this.matchDataConfig = is.readStruct(12, false, this.matchDataConfig);
  this.matchRealTimeDataConfig = is.readStruct(13, false, this.matchRealTimeDataConfig);
  this.matchEventsIframeConfig = is.readStruct(14, false, this.matchEventsIframeConfig);
};
NimoBuss.TagConfigModel = TagConfigModel;
export default TagConfigModel;