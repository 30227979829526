import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './ActivityPositionManager';
var RoomActivityView = function RoomActivityView() {
  this.specialActivity = new NimoBuss.ActivityPositionManager();
  this.normalActivityList = new Taf.Vector(new NimoBuss.ActivityPositionManager());
  this.giftActivity = new NimoBuss.ActivityPositionManager();
  this.exclusiveActivity = new NimoBuss.ActivityPositionManager();
  this.diamondActivity = new NimoBuss.ActivityPositionManager();
  this.gemActivity = new NimoBuss.ActivityPositionManager();
};
RoomActivityView.prototype._clone = function () {
  return new NimoBuss.RoomActivityView();
};
RoomActivityView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomActivityView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomActivityView.prototype.writeTo = function (os) {
  os.writeStruct(0, this.specialActivity);
  os.writeVector(1, this.normalActivityList);
  os.writeStruct(2, this.giftActivity);
  os.writeStruct(3, this.exclusiveActivity);
  os.writeStruct(4, this.diamondActivity);
  os.writeStruct(5, this.gemActivity);
};
RoomActivityView.prototype.readFrom = function (is) {
  this.specialActivity = is.readStruct(0, false, this.specialActivity);
  this.normalActivityList = is.readVector(1, false, this.normalActivityList);
  this.giftActivity = is.readStruct(2, false, this.giftActivity);
  this.exclusiveActivity = is.readStruct(3, false, this.exclusiveActivity);
  this.diamondActivity = is.readStruct(4, false, this.diamondActivity);
  this.gemActivity = is.readStruct(5, false, this.gemActivity);
};
NimoBuss.RoomActivityView = RoomActivityView;
export default RoomActivityView;