import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PayChatOption';
import './PayChatRecord';
var GetPayChatListRsp = function GetPayChatListRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vPayChatOption = new Taf.Vector(new Nimo.PayChatOption());
  this.vPayChatRecord = new Taf.Vector(new Nimo.PayChatRecord());
  this.iRecordSizeLimit = 0;
  this.iGuardianRemainCount = 0;
  this.tPayChatOption4Guardian = new Nimo.PayChatOption();
  this.vSpecialPayChatOption = new Taf.Vector(new Nimo.PayChatOption());
};
GetPayChatListRsp.prototype._clone = function () {
  return new Nimo.GetPayChatListRsp();
};
GetPayChatListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPayChatListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPayChatListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeVector(2, this.vPayChatOption);
  os.writeVector(3, this.vPayChatRecord);
  os.writeInt32(4, this.iRecordSizeLimit);
  os.writeInt32(5, this.iGuardianRemainCount);
  os.writeStruct(6, this.tPayChatOption4Guardian);
  os.writeVector(7, this.vSpecialPayChatOption);
};
GetPayChatListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vPayChatOption = is.readVector(2, false, this.vPayChatOption);
  this.vPayChatRecord = is.readVector(3, false, this.vPayChatRecord);
  this.iRecordSizeLimit = is.readInt32(4, false, this.iRecordSizeLimit);
  this.iGuardianRemainCount = is.readInt32(5, false, this.iGuardianRemainCount);
  this.tPayChatOption4Guardian = is.readStruct(6, false, this.tPayChatOption4Guardian);
  this.vSpecialPayChatOption = is.readVector(7, false, this.vSpecialPayChatOption);
};
Nimo.GetPayChatListRsp = GetPayChatListRsp;
export default GetPayChatListRsp;