import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './MatchPKConfig';
import './LiveRoomView';
import './TagConfigModel';
var WapMatchConfigModel = function WapMatchConfigModel() {
  this.wapBackImg = "";
  this.wapHeadImg = "";
  this.wapInnerImg = "";
  this.moduleOutShow = 0;
  this.style = 0;
  this.hasPk = 0;
  this.teamPKConfig = new NimoBuss.MatchPKConfig();
  this.roomViewList = new Taf.Vector(new NimoBuss.LiveRoomView());
  this.configModelList = new NimoBuss.TagConfigModel();
  this.enable = 0;
};
WapMatchConfigModel.prototype._clone = function () {
  return new NimoBuss.WapMatchConfigModel();
};
WapMatchConfigModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WapMatchConfigModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WapMatchConfigModel.prototype.writeTo = function (os) {
  os.writeString(0, this.wapBackImg);
  os.writeString(1, this.wapHeadImg);
  os.writeString(2, this.wapInnerImg);
  os.writeInt32(3, this.moduleOutShow);
  os.writeInt32(4, this.style);
  os.writeInt32(5, this.hasPk);
  os.writeStruct(6, this.teamPKConfig);
  os.writeVector(7, this.roomViewList);
  os.writeStruct(8, this.configModelList);
  os.writeInt32(9, this.enable);
};
WapMatchConfigModel.prototype.readFrom = function (is) {
  this.wapBackImg = is.readString(0, false, this.wapBackImg);
  this.wapHeadImg = is.readString(1, false, this.wapHeadImg);
  this.wapInnerImg = is.readString(2, false, this.wapInnerImg);
  this.moduleOutShow = is.readInt32(3, false, this.moduleOutShow);
  this.style = is.readInt32(4, false, this.style);
  this.hasPk = is.readInt32(5, false, this.hasPk);
  this.teamPKConfig = is.readStruct(6, false, this.teamPKConfig);
  this.roomViewList = is.readVector(7, false, this.roomViewList);
  this.configModelList = is.readStruct(8, false, this.configModelList);
  this.enable = is.readInt32(9, false, this.enable);
};
NimoBuss.WapMatchConfigModel = WapMatchConfigModel;
export default WapMatchConfigModel;