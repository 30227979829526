import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var GetFanActivitySwitchRsp = function GetFanActivitySwitchRsp() {
  this.country = "";
  this.state = 0;
};
GetFanActivitySwitchRsp.prototype._clone = function () {
  return new NimoBuss.GetFanActivitySwitchRsp();
};
GetFanActivitySwitchRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanActivitySwitchRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanActivitySwitchRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.country);
  os.writeInt32(1, this.state);
};
GetFanActivitySwitchRsp.prototype.readFrom = function (is) {
  this.country = is.readString(0, false, this.country);
  this.state = is.readInt32(1, false, this.state);
};
NimoBuss.GetFanActivitySwitchRsp = GetFanActivitySwitchRsp;
export default GetFanActivitySwitchRsp;