import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './PageControlView';
import './FanGroupRankInfo';
var GetFanRankListRsp = function GetFanRankListRsp() {
  this.pageControlView = new NimoBuss.PageControlView();
  this.fanGroupRankInfoList = new Taf.Vector(new NimoBuss.FanGroupRankInfo());
  this.currentUserRankInfo = new NimoBuss.FanGroupRankInfo();
  this.status = 0;
};
GetFanRankListRsp.prototype._clone = function () {
  return new NimoBuss.GetFanRankListRsp();
};
GetFanRankListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanRankListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanRankListRsp.prototype.writeTo = function (os) {
  os.writeStruct(0, this.pageControlView);
  os.writeVector(1, this.fanGroupRankInfoList);
  os.writeStruct(2, this.currentUserRankInfo);
  os.writeInt32(3, this.status);
};
GetFanRankListRsp.prototype.readFrom = function (is) {
  this.pageControlView = is.readStruct(0, false, this.pageControlView);
  this.fanGroupRankInfoList = is.readVector(1, false, this.fanGroupRankInfoList);
  this.currentUserRankInfo = is.readStruct(2, false, this.currentUserRankInfo);
  this.status = is.readInt32(3, false, this.status);
};
NimoBuss.GetFanRankListRsp = GetFanRankListRsp;
export default GetFanRankListRsp;