import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BadgeInfoView';
var GetFanGroupRelationAndTickeRsp = function GetFanGroupRelationAndTickeRsp() {
  this.badgeInfoView = new NimoBuss.BadgeInfoView();
  this.ticketGiftId = 0;
};
GetFanGroupRelationAndTickeRsp.prototype._clone = function () {
  return new NimoBuss.GetFanGroupRelationAndTickeRsp();
};
GetFanGroupRelationAndTickeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanGroupRelationAndTickeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanGroupRelationAndTickeRsp.prototype.writeTo = function (os) {
  os.writeStruct(0, this.badgeInfoView);
  os.writeInt32(1, this.ticketGiftId);
};
GetFanGroupRelationAndTickeRsp.prototype.readFrom = function (is) {
  this.badgeInfoView = is.readStruct(0, false, this.badgeInfoView);
  this.ticketGiftId = is.readInt32(1, false, this.ticketGiftId);
};
NimoBuss.GetFanGroupRelationAndTickeRsp = GetFanGroupRelationAndTickeRsp;
export default GetFanGroupRelationAndTickeRsp;