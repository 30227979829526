import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './AllScheduleView';
import './PageInfo';
var ListScheduleRsp = function ListScheduleRsp() {
  this.data = new Taf.Vector(new NimoBuss.AllScheduleView());
  this.pageInfo = new NimoBuss.PageInfo();
};
ListScheduleRsp.prototype._clone = function () {
  return new NimoBuss.ListScheduleRsp();
};
ListScheduleRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ListScheduleRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ListScheduleRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.data);
  os.writeStruct(1, this.pageInfo);
};
ListScheduleRsp.prototype.readFrom = function (is) {
  this.data = is.readVector(0, false, this.data);
  this.pageInfo = is.readStruct(1, false, this.pageInfo);
};
NimoBuss.ListScheduleRsp = ListScheduleRsp;
export default ListScheduleRsp;