import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GuardianUserInfo';
var GuardianSelfInfoChangeNotice = function GuardianSelfInfoChangeNotice() {
  this.lUID = 0;
  this.tSelfInfo = new Nimo.GuardianUserInfo();
};
GuardianSelfInfoChangeNotice.prototype._clone = function () {
  return new Nimo.GuardianSelfInfoChangeNotice();
};
GuardianSelfInfoChangeNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianSelfInfoChangeNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianSelfInfoChangeNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeStruct(1, this.tSelfInfo);
};
GuardianSelfInfoChangeNotice.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.tSelfInfo = is.readStruct(1, false, this.tSelfInfo);
};
Nimo.GuardianSelfInfoChangeNotice = GuardianSelfInfoChangeNotice;
export default GuardianSelfInfoChangeNotice;