import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './EventModel';
import './TeamModel';
var ScheduleModel = function ScheduleModel() {
  this.id = 0;
  this.roomId = 0;
  this.roomSort = 0;
  this.startDate = "";
  this.scheduleStatus = 0;
  this.teamSupportSwitch = 0;
  this.status = 0;
  this.lcid = 0;
  this.countryCode = "";
  this.event = new NimoBuss.EventModel();
  this.teamList = new Taf.Vector(new NimoBuss.TeamModel());
  this.name = "";
  this.anchorId = 0;
  this.gameType = 0;
  this.gameName = "";
  this.alise = "";
  this.vid = "";
  this.dotType = 0;
  this.endDate = "";
  this.videoIdList = new Taf.Vector(new Taf.INT64());
  this.videoId = "";
};
ScheduleModel.prototype._clone = function () {
  return new NimoBuss.ScheduleModel();
};
ScheduleModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ScheduleModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ScheduleModel.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeInt64(1, this.roomId);
  os.writeInt32(2, this.roomSort);
  os.writeString(3, this.startDate);
  os.writeInt32(4, this.scheduleStatus);
  os.writeInt32(5, this.teamSupportSwitch);
  os.writeInt32(6, this.status);
  os.writeInt32(7, this.lcid);
  os.writeString(8, this.countryCode);
  os.writeStruct(9, this.event);
  os.writeVector(10, this.teamList);
  os.writeString(11, this.name);
  os.writeInt64(12, this.anchorId);
  os.writeInt32(13, this.gameType);
  os.writeString(14, this.gameName);
  os.writeString(15, this.alise);
  os.writeString(16, this.vid);
  os.writeInt32(17, this.dotType);
  os.writeString(18, this.endDate);
  os.writeVector(19, this.videoIdList);
  os.writeString(20, this.videoId);
};
ScheduleModel.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.roomId = is.readInt64(1, false, this.roomId);
  this.roomSort = is.readInt32(2, false, this.roomSort);
  this.startDate = is.readString(3, false, this.startDate);
  this.scheduleStatus = is.readInt32(4, false, this.scheduleStatus);
  this.teamSupportSwitch = is.readInt32(5, false, this.teamSupportSwitch);
  this.status = is.readInt32(6, false, this.status);
  this.lcid = is.readInt32(7, false, this.lcid);
  this.countryCode = is.readString(8, false, this.countryCode);
  this.event = is.readStruct(9, false, this.event);
  this.teamList = is.readVector(10, false, this.teamList);
  this.name = is.readString(11, false, this.name);
  this.anchorId = is.readInt64(12, false, this.anchorId);
  this.gameType = is.readInt32(13, false, this.gameType);
  this.gameName = is.readString(14, false, this.gameName);
  this.alise = is.readString(15, false, this.alise);
  this.vid = is.readString(16, false, this.vid);
  this.dotType = is.readInt32(17, false, this.dotType);
  this.endDate = is.readString(18, false, this.endDate);
  this.videoIdList = is.readVector(19, false, this.videoIdList);
  this.videoId = is.readString(20, false, this.videoId);
};
NimoBuss.ScheduleModel = ScheduleModel;
export default ScheduleModel;