import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FanGroupRankInfo = function FanGroupRankInfo() {
  this.index = 0;
  this.avatar = "";
  this.royalIcon = "";
  this.royalLevel = 0;
  this.nickName = "";
  this.level = 0;
  this.badgeIcon = "";
  this.badgeName = "";
  this.udbUserId = 0;
  this.intimacy = 0;
  this.avatarBoxUrl = "";
  this.dynamicAvatarBoxUrl = "";
  this.iScene = 0;
  this.leftBadgeIcon = "";
  this.leftBadgeIconV2 = "";
};
FanGroupRankInfo.prototype._clone = function () {
  return new NimoBuss.FanGroupRankInfo();
};
FanGroupRankInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FanGroupRankInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FanGroupRankInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.index);
  os.writeString(1, this.avatar);
  os.writeString(2, this.royalIcon);
  os.writeInt32(3, this.royalLevel);
  os.writeString(4, this.nickName);
  os.writeInt32(5, this.level);
  os.writeString(6, this.badgeIcon);
  os.writeString(7, this.badgeName);
  os.writeInt64(8, this.udbUserId);
  os.writeInt64(9, this.intimacy);
  os.writeString(10, this.avatarBoxUrl);
  os.writeString(11, this.dynamicAvatarBoxUrl);
  os.writeInt32(12, this.iScene);
  os.writeString(13, this.leftBadgeIcon);
  os.writeString(14, this.leftBadgeIconV2);
};
FanGroupRankInfo.prototype.readFrom = function (is) {
  this.index = is.readInt32(0, false, this.index);
  this.avatar = is.readString(1, false, this.avatar);
  this.royalIcon = is.readString(2, false, this.royalIcon);
  this.royalLevel = is.readInt32(3, false, this.royalLevel);
  this.nickName = is.readString(4, false, this.nickName);
  this.level = is.readInt32(5, false, this.level);
  this.badgeIcon = is.readString(6, false, this.badgeIcon);
  this.badgeName = is.readString(7, false, this.badgeName);
  this.udbUserId = is.readInt64(8, false, this.udbUserId);
  this.intimacy = is.readInt64(9, false, this.intimacy);
  this.avatarBoxUrl = is.readString(10, false, this.avatarBoxUrl);
  this.dynamicAvatarBoxUrl = is.readString(11, false, this.dynamicAvatarBoxUrl);
  this.iScene = is.readInt32(12, false, this.iScene);
  this.leftBadgeIcon = is.readString(13, false, this.leftBadgeIcon);
  this.leftBadgeIconV2 = is.readString(14, false, this.leftBadgeIconV2);
};
NimoBuss.FanGroupRankInfo = FanGroupRankInfo;
export default FanGroupRankInfo;