import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './EventsTeam';
var GroupTeamModel = function GroupTeamModel() {
  this.groupTitle = "";
  this.subTeamList = new Taf.Vector(new NimoBuss.EventsTeam());
};
GroupTeamModel.prototype._clone = function () {
  return new NimoBuss.GroupTeamModel();
};
GroupTeamModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GroupTeamModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GroupTeamModel.prototype.writeTo = function (os) {
  os.writeString(0, this.groupTitle);
  os.writeVector(1, this.subTeamList);
};
GroupTeamModel.prototype.readFrom = function (is) {
  this.groupTitle = is.readString(0, false, this.groupTitle);
  this.subTeamList = is.readVector(1, false, this.subTeamList);
};
NimoBuss.GroupTeamModel = GroupTeamModel;
export default GroupTeamModel;