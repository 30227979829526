import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { eventEmitter, EVENTS } from '@client/common/utils/events';
import EPayChatSourceType from '@client/jce/Nimo/EPayChatSourceType';
export var offTextForbid = function offTextForbid(handler) {
  eventEmitter.off(EVENTS.TEXT_FORBID, handler);
};
export var onTextForbid = function onTextForbid(handler) {
  eventEmitter.on(EVENTS.TEXT_FORBID, handler);
};
export var emitTextForbid = function emitTextForbid(forbidMsg) {
  return eventEmitter.emit(EVENTS.TEXT_FORBID, forbidMsg);
};
export var isForbid = function isForbid(forbidMsg, msg) {
  if (!msg) return false;
  if (msg.__msgtype__ === 1400) {
    // MessageNotice
    if (msg.tUserInfo.lUid === forbidMsg.lForbidUid) {
      return true;
    }
  } else if (msg.__msgtype__ === 1419) {
    // EUriPayChatNotice
    // PayChatNotice
    if (msg.lUid === forbidMsg.lForbidUid) {
      return true;
    }
  } else if (msg.__msgtype__ === 1428) {
    // EUriPayChatRichTextNewNotice
    if (msg.lUid === forbidMsg.lForbidUid && msg.iSource === EPayChatSourceType.kPAYCHAT_NORMAL) {
      return true;
    }
  }
  return false;
};
export var setTextForbidImmutable = function setTextForbidImmutable(forbidMsg, msgList) {
  var changed = false;
  if (!Array.isArray(msgList)) return msgList;
  var newMsgList = msgList.map(function (item) {
    if (isForbid(forbidMsg, item)) {
      changed = true;
      return _objectSpread(_objectSpread({}, item), {}, {
        __banned__: true
      });
    }
    return item;
  });
  return changed ? newMsgList : msgList;
};
export var setTextForbidMutable = function setTextForbidMutable(forBidMsg, msgList) {
  if (!Array.isArray(msgList)) return;
  msgList.forEach(function (item) {
    if (isForbid(forBidMsg, item)) {
      // eslint-disable-next-line no-param-reassign
      item.__banned__ = true;
    }
  });
};