import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FansUpgradeAwardMsg = function FansUpgradeAwardMsg() {
  this.fansId = 0;
  this.anchorId = 0;
  this.level = 0;
  this.intimate = 0;
  this.giftId = 0;
  this.icon = "";
};
FansUpgradeAwardMsg.prototype._clone = function () {
  return new NimoBuss.FansUpgradeAwardMsg();
};
FansUpgradeAwardMsg.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FansUpgradeAwardMsg.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FansUpgradeAwardMsg.prototype.writeTo = function (os) {
  os.writeInt64(0, this.fansId);
  os.writeInt64(1, this.anchorId);
  os.writeInt32(2, this.level);
  os.writeInt64(3, this.intimate);
  os.writeInt32(4, this.giftId);
  os.writeString(5, this.icon);
};
FansUpgradeAwardMsg.prototype.readFrom = function (is) {
  this.fansId = is.readInt64(0, false, this.fansId);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.level = is.readInt32(2, false, this.level);
  this.intimate = is.readInt64(3, false, this.intimate);
  this.giftId = is.readInt32(4, false, this.giftId);
  this.icon = is.readString(5, false, this.icon);
};
NimoBuss.FansUpgradeAwardMsg = FansUpgradeAwardMsg;
export default FansUpgradeAwardMsg;