import EURI from '@client/jce/Nimo/EURI';
export default function filterMsgListByRoomId(msgList, roomId) {
  var bypassSelfSender = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (roomId <= 0 || isNaN(roomId)) {
    return msgList;
  }
  var filter = function filter(msg) {
    if (msg) {
      var _msg$data;
      if (msg.lSenderUid) {
        return true;
      }
      // 根据roomId过滤
      var msgRoomId = msg.__msgtype__ === EURI.EUriJServerPacket ? (_msg$data = msg.data) === null || _msg$data === void 0 ? void 0 : _msg$data.roomId : msg.lRoomId;
      if (msgRoomId > 0) {
        if (+msgRoomId === +roomId) {
          return true;
        } else if (bypassSelfSender && msg.lSenderUid && msg.lSenderUid === bypassSelfSender) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  return msgList.filter(filter);
}