import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './GroupTeamModel';
var EventTeamListView = function EventTeamListView() {
  this.teamList = new Taf.Vector(new NimoBuss.GroupTeamModel());
};
EventTeamListView.prototype._clone = function () {
  return new NimoBuss.EventTeamListView();
};
EventTeamListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EventTeamListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EventTeamListView.prototype.writeTo = function (os) {
  os.writeVector(0, this.teamList);
};
EventTeamListView.prototype.readFrom = function (is) {
  this.teamList = is.readVector(0, false, this.teamList);
};
NimoBuss.EventTeamListView = EventTeamListView;
export default EventTeamListView;