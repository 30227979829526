import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var TeamModel = function TeamModel() {
  this.id = 0;
  this.name = "";
  this.logo = "";
  this.badge = "";
  this.score = 0;
  this.createTime = "";
  this.updateTime = "";
  this.introduction = "";
  this.introductionLang = "";
};
TeamModel.prototype._clone = function () {
  return new NimoBuss.TeamModel();
};
TeamModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TeamModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TeamModel.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.name);
  os.writeString(2, this.logo);
  os.writeString(3, this.badge);
  os.writeInt32(4, this.score);
  os.writeString(5, this.createTime);
  os.writeString(6, this.updateTime);
  os.writeString(7, this.introduction);
  os.writeString(8, this.introductionLang);
};
TeamModel.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.name = is.readString(1, false, this.name);
  this.logo = is.readString(2, false, this.logo);
  this.badge = is.readString(3, false, this.badge);
  this.score = is.readInt32(4, false, this.score);
  this.createTime = is.readString(5, false, this.createTime);
  this.updateTime = is.readString(6, false, this.updateTime);
  this.introduction = is.readString(7, false, this.introduction);
  this.introductionLang = is.readString(8, false, this.introductionLang);
};
NimoBuss.TeamModel = TeamModel;
export default TeamModel;