import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GuardianLotteryResult = function GuardianLotteryResult() {
  this.lUID = 0;
  this.lDiamond = 0;
  this.sNickName = "";
};
GuardianLotteryResult.prototype._clone = function () {
  return new Nimo.GuardianLotteryResult();
};
GuardianLotteryResult.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianLotteryResult.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianLotteryResult.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeInt64(1, this.lDiamond);
  os.writeString(2, this.sNickName);
};
GuardianLotteryResult.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.lDiamond = is.readInt64(1, false, this.lDiamond);
  this.sNickName = is.readString(2, false, this.sNickName);
};
Nimo.GuardianLotteryResult = GuardianLotteryResult;
export default GuardianLotteryResult;