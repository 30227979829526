import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GetForceLoginSwitchRsp = function GetForceLoginSwitchRsp() {
  this.iOpen = 0;
};
GetForceLoginSwitchRsp.prototype._clone = function () {
  return new Nimo.GetForceLoginSwitchRsp();
};
GetForceLoginSwitchRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetForceLoginSwitchRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetForceLoginSwitchRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iOpen);
};
GetForceLoginSwitchRsp.prototype.readFrom = function (is) {
  this.iOpen = is.readInt32(0, false, this.iOpen);
};
Nimo.GetForceLoginSwitchRsp = GetForceLoginSwitchRsp;
export default GetForceLoginSwitchRsp;