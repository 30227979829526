import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetForceLoginSwitchReq = function GetForceLoginSwitchReq() {
  this.tUser = new Nimo.UserId();
};
GetForceLoginSwitchReq.prototype._clone = function () {
  return new Nimo.GetForceLoginSwitchReq();
};
GetForceLoginSwitchReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetForceLoginSwitchReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetForceLoginSwitchReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
};
GetForceLoginSwitchReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
};
Nimo.GetForceLoginSwitchReq = GetForceLoginSwitchReq;
export default GetForceLoginSwitchReq;