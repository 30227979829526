import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './WonderfulEventItemView';
import './PageInfo';
var GetEventListRsp = function GetEventListRsp() {
  this.eventList = new Taf.Vector(new NimoBuss.WonderfulEventItemView());
  this.pageInfo = new NimoBuss.PageInfo();
};
GetEventListRsp.prototype._clone = function () {
  return new NimoBuss.GetEventListRsp();
};
GetEventListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEventListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEventListRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.eventList);
  os.writeStruct(1, this.pageInfo);
};
GetEventListRsp.prototype.readFrom = function (is) {
  this.eventList = is.readVector(0, false, this.eventList);
  this.pageInfo = is.readStruct(1, false, this.pageInfo);
};
NimoBuss.GetEventListRsp = GetEventListRsp;
export default GetEventListRsp;