import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
import './UidNickName';
var SendPayChatReq = function SendPayChatReq() {
  this.tUser = new Nimo.UserId();
  this.lAnchorId = 0;
  this.sContent = "";
  this.iType = 0;
  this.vAtSomeone = new Taf.Vector(new Nimo.UidNickName());
};
SendPayChatReq.prototype._clone = function () {
  return new Nimo.SendPayChatReq();
};
SendPayChatReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendPayChatReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendPayChatReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lAnchorId);
  os.writeString(2, this.sContent);
  os.writeInt32(3, this.iType);
  os.writeVector(4, this.vAtSomeone);
};
SendPayChatReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lAnchorId = is.readInt64(1, false, this.lAnchorId);
  this.sContent = is.readString(2, false, this.sContent);
  this.iType = is.readInt32(3, false, this.iType);
  this.vAtSomeone = is.readVector(4, false, this.vAtSomeone);
};
Nimo.SendPayChatReq = SendPayChatReq;
export default SendPayChatReq;