import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import fanGroupModel from '@client/common/models/follow/fanGroup';
import { updateFanGroupInfo } from './actions';
export function updateFanGroupInfoAsync() {
  return function (dispatch, getState) {
    var _ref = getState().home.room || {},
      _ref$roomInfo = _ref.roomInfo,
      _ref$roomInfo2 = _ref$roomInfo === void 0 ? {} : _ref$roomInfo,
      auId = _ref$roomInfo2.auId,
      _ref$userInfo = _ref.userInfo,
      _ref$userInfo2 = _ref$userInfo === void 0 ? {} : _ref$userInfo,
      uid = _ref$userInfo2.uid;
    if (auId) {
      return fanGroupModel.getFanGroupRelationAndTicket({
        anchorId: auId,
        fanId: uid || 0
      }).then(function (_ref2) {
        var ticketGiftId = _ref2.ticketGiftId,
          fanGroupInfo = _ref2.badgeInfoView;
        dispatch(updateFanGroupInfo(_objectSpread(_objectSpread({
          ticketGiftId: ticketGiftId
        }, fanGroupInfo), {}, {
          leftBadgeIcon: fanGroupInfo.leftBadgeIconV2,
          // 手动将leftBadgeIconV2赋值给leftBadgeIcon，防止有的地方直接用了旧的字段
          relation: fanGroupInfo.badgeStatus // 手动增加一下旧字段名
        })));
      }).catch(function () {});
    }
    return Promise.resolve();
  };
}