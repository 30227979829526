import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './ScheduleModel';
var AllScheduleView = function AllScheduleView() {
  this.date = 0;
  this.eventList = new Taf.Vector(new NimoBuss.ScheduleModel());
};
AllScheduleView.prototype._clone = function () {
  return new NimoBuss.AllScheduleView();
};
AllScheduleView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AllScheduleView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AllScheduleView.prototype.writeTo = function (os) {
  os.writeInt64(1, this.date);
  os.writeVector(2, this.eventList);
};
AllScheduleView.prototype.readFrom = function (is) {
  this.date = is.readInt64(1, false, this.date);
  this.eventList = is.readVector(2, false, this.eventList);
};
NimoBuss.AllScheduleView = AllScheduleView;
export default AllScheduleView;