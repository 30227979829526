import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ERoomAnimationType = {
  EANIM_TYPE_ROYAL_UPGRADE: 1,
  EANIM_TYPE_SUBSCRIBE: 2,
  EANIM_TYPE_ROYAL_RENEWAL: 3,
  EANIM_TYPE_ACTIVITY_BOMB: 4,
  EANIM_TYPE_COMMON: 5,
  EANIM_TYPE_FANGROUP_UPGRADE: 6,
  EANIM_TYPE_WEEKSTAR_ANCHOR: 7,
  EANIM_TYPE_SHOW_LEVEL_UP: 8
};
Nimo.ERoomAnimationType = ERoomAnimationType;
export default ERoomAnimationType;