import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var VideoType = {
  ORIGINALTYPE: 1,
  HIGHBITRATETYPE: 2,
  MIDBITRATETYPE: 3,
  LOWBITRATETYPE: 4,
  VERYLOWBITRATETYPE: 5,
  VERYHIGHBITRATETYPE: 6,
  VERYHIGHBITRATETYPE_HDR: 16
};
Nimo.VideoType = VideoType;
export default VideoType;